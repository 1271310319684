import {
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@material-ui/core";
import mainLub from "../../assets/main-approved.jpg";
import "./styles.css";

import universalPhoto from "../../assets/universal-1-2.jpg";
import teamlabPhoto from "../../assets/team-lab-1-1.jpg";
import liHolidayPhoto from "../../assets/li-holiday-1-1.jpg";
import hawaiiPhoto from "../../assets/hawaii-2-1.jpg";
import samuraiPhoto from "../../assets/samurai-1-1.jpg";
import familyPhoto from "../../assets/family-portrait-1-1.jpg";
import poohBearPhoto from "../../assets/pooh-bear-1-1.jpg";
import tahoeTripPhoto from "../../assets/tahoe-trip-1-1.jpg";
import peanutShadowsPhoto from "../../assets/peanut-shadows-1-1.jpg";
import blackpinkTourPhoto from "../../assets/blackpink-tour-1-1.jpg";
import axCosplayPhoto from "../../assets/ax-cosplay-1-1.jpg";

import sanDiegoPhoto from "../../assets/san-diego-1-2.jpg";

const meetingYear = 2018;
const currentYear = new Date(Date.now()).getFullYear();

export function GalleryTab(): JSX.Element {
  const windowSize = window.screen.width;

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} md={4} alignContent="center">
        <img
          src={mainLub}
          alt="Charlie & Winnie image"
          className="gallery-header-image"
        />
      </Grid>
      <Grid item xs={12} md={8} alignContent="center">
        <Typography color="textSecondary" paragraph>
          Winnie and Charlie met {currentYear - meetingYear} years ago,
          initially bonding over a mutual love of Taylor Swift. On their first
          date, these two nervous souls had San Jose's best breakfast burritos
          from Cafe Rosalena and then spent the rest of the day at an animal
          shelter. They quickly realized that they had a lot in common and began
          talking every day after. They became each other's best friend and
          confidant.
        </Typography>
        <Typography color="textSecondary" paragraph>
          Over the next {currentYear - meetingYear} years, Charlie and Winnie
          faced many challenges, but they grew together and became stronger as a
          couple. They discovered more about themselves through their
          relationship and continued to love each other even as they evolved as
          people. They supported each other through tough times and celebrated
          each other's successes.
        </Typography>
        <Typography color="textSecondary" paragraph>
          Charlie and Winnie have a bond that is unbreakable and a love that is
          pure. They are each other's soulmate and they know that they are meant
          to be together forever. They have been strongly supported by friends
          and family and blessed to experience so much love and joy in their
          lives. This is why they've invited those who have deeply impacted them
          to witness the couple exchange vows... promises to love one another
          for the rest of their lives.
        </Typography>
        <Typography color="textSecondary" paragraph>
          Dear family or friend whom Charlie and Winnie hold dear, please enjoy
          our celebration together!
        </Typography>
      </Grid>
      <Grid item xs={12} className="gallery-images">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={4}>
            <div style={{ position: "relative" }}>
              <img
                src={universalPhoto}
                alt="at Universal Studios"
                className="image-list-tall-img"
              />
              <ImageListItemBar
                title="First time at Universal Hollywood"
                subtitle="Universal City, CA"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} md={6}>
                <div style={{ position: "relative" }}>
                  <img
                    src={teamlabPhoto}
                    alt="at team labs Japan"
                    className="image-list-square-img"
                  />
                  <ImageListItemBar
                    title="Team Labs Borderless"
                    subtitle="Tokyo, Japan"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{ position: "relative" }}>
                  <img
                    src={liHolidayPhoto}
                    alt="linkedin holiday party"
                    className="image-list-square-img"
                  />
                  <ImageListItemBar
                    title="A scary surprise"
                    subtitle="San Mateo, CA"
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{ position: "relative" }}>
                  <img
                    src={hawaiiPhoto}
                    alt="hawaii scenic"
                    className="image-list-long-img"
                  />
                  <ImageListItemBar
                    title="Beautiful view!"
                    subtitle="The Oahu scenery is nice too."
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <div style={{ position: "relative" }}>
              <img
                src={familyPhoto}
                alt="family photo"
                className="image-list-square-img"
              />
              <ImageListItemBar
                title="First family photo"
                subtitle="San Jose, CA"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{ position: "relative" }}>
              <img
                src={samuraiPhoto}
                alt="samurai attack"
                className="image-list-square-img"
              />
              <ImageListItemBar
                title="Don't make Winnie angry"
                subtitle="Kyoto, Japan"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{ position: "relative" }}>
              <img
                src={poohBearPhoto}
                alt="with Winnie the Pooh"
                className="image-list-square-img"
              />
              <ImageListItemBar
                title="Winnie meeting Winnie"
                subtitle="Anaheim, CA"
              />
            </div>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} md={6}>
                <GetGalleryImage
                  src={tahoeTripPhoto}
                  alt="Trip to tahoe"
                  type="square"
                  title="The snowiest trip"
                  subtitle="Lake Tahoe, CA"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GetGalleryImage
                  src={peanutShadowsPhoto}
                  alt="Peanut smile"
                  type="square"
                  title="Beautiful baby"
                  subtitle="at the park"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GetGalleryImage
                  src={blackpinkTourPhoto}
                  alt="BlackPink tour"
                  type="square"
                  title="BLACKPINK Tour"
                  subtitle="Houston, TX"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GetGalleryImage
                  src={axCosplayPhoto}
                  alt="Cosplay at Anime Expo"
                  type="square"
                  title="Supportive cosplay wifey"
                  subtitle="Los Angeles, CA"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <GetGalleryImage
              src={sanDiegoPhoto}
              alt="Kissing statue San Diego"
              type="tall"
              title="Kissing statue"
              subtitle="San Diego, CA"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const GetGalleryImage = (props: {
  src: string;
  alt: string;
  type: string;
  title?: string;
  subtitle?: string;
}): JSX.Element => {
  const { src, alt, type, title, subtitle } = props;

  return (
    <div style={{ position: "relative" }}>
      <img src={src} alt={alt} className={"image-list-" + type + "-img"} />
      {title ? <ImageListItemBar title={title} subtitle={subtitle} /> : null}
    </div>
  );
};
