import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Album from "./components/main";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";
import { ViewerContextManager } from "./components/viewer-context/component";

const theme = createTheme({
  palette: {
    primary: {
      // main: "#AACB73",
      main: "#FFD4D4",
    },
    secondary: {
      // main: "#CDE990",
      main: "#FFFFE8",
    },
    text: {
      primary: "#FFFFE8",
      secondary: "rgba(0,0,0,0.54)",
    },
  },
  typography: {
    h2: {
      fontFamily:
        "'Shadows Into Light',-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ViewerContextManager>
          <Album />
        </ViewerContextManager>
      </ThemeProvider>
    </div>
  );
}

export default App;
