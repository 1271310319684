import { Grid } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import SignIn from "./login";
import { useViewerContext } from "../viewer-context/component";
import { GuestInfoRSVP } from "./guest-info";
import { LoadingSpinnner } from "../common/loading-spinner";

export function RSVPTab(): JSX.Element {
  const viewerContext = useViewerContext();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const isLoggedIn = Boolean(viewerContext?.isLoggedIn);

  const onSubmit = useCallback(
    async (email: string, firstName: string, lastName: string) => {
      viewerContext?.resetLoginState();
      setIsSubmitLoading(true);

      // Make a POST request to the backend using Fetch API
      const response = await fetch("https://api.winnieandcharlie.com/auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email.trim(),
          firstName: firstName.trim(),
          lastName: lastName.trim(),
        }),
        credentials: "include",
      });

      setIsSubmitLoading(false);

      // Response status 0 is being checked because fetch wants to be stupid
      if (response.status === 200 || response.status === 0) {
        viewerContext?.setEmail(email);
        viewerContext?.setLoggedIn(true);
      } else if (response.status === 403) {
        viewerContext?.forbidLogin();
      }
    },
    [viewerContext]
  );

  const guestInfoComponent = useMemo(() => <GuestInfoRSVP />, []);

  return (
    <>{isLoggedIn ? guestInfoComponent : <SignIn onSubmit={onSubmit} />}</>
    // <Grid container spacing={4} justifyContent="center">
    //   <Grid item xs={12} md={6} alignContent="center">

    //   </Grid>

    //   <button onClick={onClick}>RSVP</button>
    // </Grid>
  );
}
