import React, { useEffect, useRef, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { Menu as MenuIcon } from "@material-ui/icons";
import { Tab, Tabs } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import logo512 from "../logo512.png";
import { MainTab } from "./main/component";
import { InfoTab } from "./info/component";
import { GalleryTab } from "./gallery/component";

import mainLubImage from "../assets/main-approved.jpg";
import universalPhoto from "../assets/universal-1-2.jpg";
import teamlabPhoto from "../assets/team-lab-1-1.jpg";
import liHolidayPhoto from "../assets/li-holiday-1-1.jpg";
import hawaiiPhoto from "../assets/hawaii-2-1.jpg";
import samuraiPhoto from "../assets/samurai-1-1.jpg";
import familyPhoto from "../assets/family-portrait-1-1.jpg";
import poohBearPhoto from "../assets/pooh-bear-1-1.jpg";
import tahoeTripPhoto from "../assets/tahoe-trip-1-1.jpg";
import peanutShadowsPhoto from "../assets/peanut-shadows-1-1.jpg";
import blackpinkTourPhoto from "../assets/blackpink-tour-1-1.jpg";
import axCosplayPhoto from "../assets/ax-cosplay-1-1.jpg";

import sanDiegoPhoto from "../assets/san-diego-1-2.jpg";
import { GiftsTab } from "./gifts/component";
import { RSVPTab } from "./rsvp/component";

const prefetchImages = [
  mainLubImage,
  universalPhoto,
  teamlabPhoto,
  liHolidayPhoto,
  hawaiiPhoto,
  samuraiPhoto,
  familyPhoto,
  poohBearPhoto,
  tahoeTripPhoto,
  peanutShadowsPhoto,
  blackpinkTourPhoto,
  axCosplayPhoto,
  sanDiegoPhoto,
];

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Charlie & Winnie
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

/*
Color palette:
Pink: #FFD4D4
Cream: #FFFFE8
Green lighter: CDE990
Green darker: AACB73
*/

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: "#FFD4D4",
    padding: theme.spacing(8, 0, 6),
    color: "#FFFFE8",
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  footer: {
    backgroundColor: "#FFD4D4",
    padding: theme.spacing(6),
    paddingBottom: "94px",
  },
}));

export default function Album() {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const bodyRef = useRef<HTMLDivElement>(null);

  const onTabChange = (_event: React.ChangeEvent<{}>, value: number) => {
    setCurrentTab(value);
    // bodyRef.current?.scrollTo(0, 0);
    window.scrollTo(0, 0);
  };

  const onMenuOpen = (event: React.MouseEvent<{}>) => {
    setMenuAnchorEl(event.target as HTMLDivElement);
  };

  const onMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const onMenuItemSelect = (value: number) => {
    setCurrentTab(value);
    onMenuClose();
    window.scrollTo(0, 0);
    // bodyRef.current?.scrollTo(0, 0);
  };

  const currentTabValueToComponentMap: Record<number, JSX.Element> = {
    0: <MainTab />,
    1: <InfoTab />,
    2: <GalleryTab />,
    3: <GiftsTab />,
    4: <RSVPTab />,
  };

  const currentTabValueToNavTitleMap: Record<number, string> = {
    0: "Main",
    1: "Day-of Info",
    2: "About Us",
    3: "Gifts",
    4: "RSVP",
  };

  const weddingDate = new Date(2023, 6, 8, 7);
  const currentDate = new Date(Date.now());

  const duration = weddingDate.getTime() - currentDate.getTime();

  const timeUntil = Math.ceil(Math.max(0, duration) / 1000 / 60 / 60 / 24);

  useEffect(() => {
    prefetchImages.forEach((item) => {
      const newImage = new Image();
      newImage.src = item;
    });
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" id="app-bar">
        <Toolbar onClick={onMenuOpen}>
          <Typography variant="h6" color="inherit" noWrap>
            {currentTabValueToNavTitleMap[currentTab]}
          </Typography>
          <MenuIcon className={classes.icon} />
        </Toolbar>
      </AppBar>
      <Menu
        id="fade-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={onMenuClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={onMenuItemSelect.bind(null, 0)}>
          <Typography color="textSecondary">Main</Typography>
        </MenuItem>
        <MenuItem onClick={onMenuItemSelect.bind(null, 1)}>
          <Typography color="textSecondary">Day-of Info</Typography>
        </MenuItem>
        <MenuItem onClick={onMenuItemSelect.bind(null, 2)}>
          <Typography color="textSecondary">About Us</Typography>
        </MenuItem>
        <MenuItem onClick={onMenuItemSelect.bind(null, 3)}>
          <Typography color="textSecondary">Gifts</Typography>
        </MenuItem>
        <MenuItem onClick={onMenuItemSelect.bind(null, 4)}>
          <Typography color="textSecondary">RSVP</Typography>
        </MenuItem>
      </Menu>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <h1 className="hero-heading"></h1>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Winnie & Charlie
            </Typography>
            <Typography
              variant="h4"
              align="center"
              color="textSecondary"
              paragraph
            >
              July 8, 2023
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              paragraph
            >
              {timeUntil} days {duration > 0 ? "until" : "since"} the big day!
              &#183; Saratoga, CA
            </Typography>
            <div className="hero-buttons">
              <Grid container spacing={2} justifyContent="center">
                <Tabs
                  value={currentTab}
                  onChange={onTabChange}
                  aria-label="Page tabs"
                  indicatorColor="secondary"
                  textColor="secondary"
                >
                  <Tab label="Main" />
                  <Tab label="Day-of Info" />
                  <Tab label="About Us" />
                  <Tab label="Gifts" />
                  <Tab label="RSVP" />
                </Tabs>
                {/* <Grid item>
                  <Button variant="contained" color="primary">
                    <Typography color="textPrimary">
                        Main
                    </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary">
                    Info
                  </Button>
                </Grid> */}
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md" ref={bodyRef}>
          {currentTabValueToComponentMap[currentTab]}
          {/* End hero unit */}
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer + " app-footer"}>
        {/* <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Lorem Ipsum Epsilon
        </Typography> */}
        <img src={logo512} style={{ width: "256px", height: "256px" }} />
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
