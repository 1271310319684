import { Grid, Typography } from "@material-ui/core";
import RedEnvelopeImage from "../../assets/R.jpg";

export function GiftsTab(): JSX.Element {
  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} md={6} alignContent="center">
        <Typography color="textSecondary" paragraph>
          We don't need any gifts, as you joining us on our special day is the
          best gift of all, and that's what means the most to us!
        </Typography>
        <Typography color="textSecondary" paragraph>
          However, if you would like to support our Honeymoon Fund, in Chinese
          and Vietnamese wedding traditions, guests may leave Red Envelopes to
          support the Bride and Groom.
        </Typography>
        <Typography color="textSecondary" paragraph>
          To support us with a Digital Red Envelope, you can send your gifts
          using the following links:
        </Typography>
        <br />
        <Typography color="textSecondary" paragraph>
          Paypal:{" "}
          <a href="https://paypal.me/winnieandcharlie2023">
            https://paypal.me/winnieandcharlie2023
          </a>
        </Typography>
        <br />
        <Typography color="textSecondary" paragraph>
          Venmo:{" "}
          <a href="https://account.venmo.com/u/charlie-tran-6">
            https://account.venmo.com/u/charlie-tran-6
          </a>
        </Typography>
        <br />
        <Typography color="textSecondary" paragraph>
          Thank you very much!
        </Typography>
      </Grid>
    </Grid>
  );
}
