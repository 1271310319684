import React, { useCallback, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { FormGroup } from "@material-ui/core";
import { useViewerContext } from "../viewer-context/component";
import { LoadingSpinnner } from "../common/loading-spinner";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props: {
  onSubmit: (e: string, f: string, l: string) => void;
  error?: Error;
  isLoading?: boolean;
}) {
  const { onSubmit } = props;
  const classes = useStyles();
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const viewerContext = useViewerContext();

  const onEmailFieldChange = useCallback((event: React.ChangeEvent) => {
    setEmail((event.target as HTMLInputElement).value);
  }, []);

  const onFirstNameFieldChange = useCallback((event: React.ChangeEvent) => {
    setFirstName((event.target as HTMLInputElement).value);
  }, []);

  const onLastNameFieldChange = useCallback((event: React.ChangeEvent) => {
    setLastName((event.target as HTMLInputElement).value);
  }, []);

  const onButtonClick = useCallback(() => {
    onSubmit(email, firstName, lastName);
  }, [onSubmit, email, firstName, lastName]);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <FormGroup className={"login-form" + " " + classes.paper}>
        {viewerContext?.loginWasForbidden && (
          <Typography component="h6" variant="h6" color="error">
            There was an issue with your login information, please enter your
            info again.
          </Typography>
        )}
        {viewerContext?.loginWasInvalidated && (
          <Typography component="h6" variant="h6" color="error">
            Your login has timed out, please enter your info again.
          </Typography>
        )}
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" color="textSecondary">
          Please enter your wedding RSVP information:
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email-input"
            label="Email Address"
            name="Email"
            autoComplete="email"
            inputProps={{ style: { color: "#1F1F1F" } }}
            autoFocus
            value={email}
            error={Boolean(props.error)}
            onChange={onEmailFieldChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="first-name-input"
            label="First Name"
            name="First Name"
            autoComplete="first-name"
            inputProps={{ style: { color: "#1F1F1F" } }}
            color="secondary"
            value={firstName}
            error={Boolean(props.error)}
            onChange={onFirstNameFieldChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="last-name-input"
            label="Last Name"
            name="Last Name"
            autoComplete="last-name"
            inputProps={{ style: { color: "#1F1F1F" } }}
            value={lastName}
            error={Boolean(props.error)}
            onChange={onLastNameFieldChange}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onButtonClick}
            disabled={Boolean(props.isLoading)}
          >
            {!Boolean(props.isLoading) ? "Submit" : <LoadingSpinnner />}
          </Button>
        </form>
        {props.error ? (
          <Typography component="p" color="error">
            {props.error?.message}
          </Typography>
        ) : null}
      </FormGroup>
    </Container>
  );
}
