import { Grid, Typography } from "@material-ui/core";
import hakoneMap from "../../assets/hakone-map.png";
import "./styles.css";

export function InfoTab(): JSX.Element {
  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      className="info-tab-container"
    >
      <Grid item xs={12} md={12} alignContent="center">
        <Typography color="textSecondary" paragraph variant="h5">
          Location
        </Typography>
        <Typography color="textSecondary" paragraph>
          <strong>Hakone Gardens</strong>
        </Typography>
        <Typography color="textSecondary" paragraph>
          21000 Big Basin Way
        </Typography>
        <Typography color="textSecondary" paragraph>
          Saratoga, CA 95070
        </Typography>
      </Grid>

      <Grid item xs={12} md={12} alignContent="center">
        <Typography color="textSecondary" paragraph variant="h5">
          Directions
        </Typography>
        <Typography color="textSecondary" paragraph>
          Hakone Gardens is right past downtown Saratoga. As you leave the main
          street and head up the mountains, the entrance will be on the left at
          a weird angle. It's not a 90 degree turn but more like taking the left
          fork up a mountain path. Please be advised that while this path is
          short and takes less than a minute to go up, it's very narrow (too
          narrow for opposing traffic to pass through at the same time) and
          quite steep (use a low gear and take it slowly). Once past this, you
          will come to a large parking lot. On the left will be the Hakone gift
          shop and office, and on the right will be the entrance to the gardens.
        </Typography>
        <img src={hakoneMap} alt="Hakone Gardens Map" className="map-image" />
        <Typography color="textSecondary" paragraph>
          Note: If you miss the entrance and are using Google Maps, you'll
          likely be routed in a big circle to go around to Bank Mill Rd,
          Stoneridge Dr, and then back to the main road. This is a perfectly
          acceptable detour and will be safer than trying to take a U-Turn as
          Winnie and I have discovered from personal experience.
        </Typography>
        <Typography color="textSecondary" paragraph>
          Note B: You shouldn't be charged for any admission to the venue as it
          has already been covered.
        </Typography>
        <Typography color="textSecondary" paragraph>
          Note C: After the ceremony, the drive down the steep, narrow winding
          path will also be dark. Please be careful when driving this and
          especially if you're a driver on this day please be careful with your
          drinks. It would break our hearts if someone got hurt leaving our
          wedding venue.
        </Typography>
      </Grid>

      <Grid item xs={12} md={12} alignContent="center">
        <Typography color="textSecondary" paragraph variant="h5">
          Timeline
        </Typography>
        <Typography color="textSecondary" paragraph>
          <Grid container spacing={2} justifyContent="center">
            <table style={{ textAlign: "left" }}>
              <tr>
                <td>4:30 PM</td>
                <td>&ensp; Doors Open</td>
              </tr>
              <tr>
                <td>5:00 PM</td>
                <td>&ensp; Ceremony Starts</td>
              </tr>
              <tr>
                <td>5:30 PM</td>
                <td>&ensp; Cocktail Hour</td>
              </tr>
              <tr>
                <td>6:30 PM</td>
                <td>&ensp; Dinner and Reception</td>
              </tr>
            </table>
          </Grid>
        </Typography>
      </Grid>

      <Grid item xs={12} md={12} alignContent="center">
        <Typography color="textSecondary" paragraph variant="h5">
          Attire
        </Typography>
        <Typography color="textSecondary" paragraph>
          Smart Casual -{" "}
          <span>
            <a href="https://wearyourlovexo.com/blogs/news/what-is-smart-casual-dress-code-for-a-wedding">
              https://wearyourlovexo.com/blogs/news/what-is-smart-casual-dress-code-for-a-wedding
            </a>
          </span>
        </Typography>
      </Grid>

      <Grid item xs={12} md={12} alignContent="center">
        <Typography color="textSecondary" paragraph variant="h5">
          Weather
        </Typography>
        <Typography color="textSecondary" paragraph>
          Checking the weather in{" "}
          <span>
            <a href="https://weather.com/weather/tenday/l/Saratoga+CA?canonicalCityId=14bcc1cb2118ee987ab7737c877b7a1da1588b10db2edb6fc33e35fab9ae142f">
              Saratoga, CA
            </a>
          </span>
          , we are hoping things will stay around 70-80 degrees for the day,
          with the night's low potentially in the 50s. By the time the reception
          is over, we would expect the temperature to reach around the 60s.
          Please be prepared to stay warm after sunset.
        </Typography>
      </Grid>

      <Grid item xs={12} md={12} alignContent="center">
        <Typography color="textSecondary" paragraph variant="h5">
          COVID-19 Precautions
        </Typography>
        <Typography color="textSecondary" paragraph>
          The wedding and reception will be held outdoors. Masks are not
          required. However, we will have an elder, immuno-compromised guest so
          we ask that if you are feeling unwell to take a COVID test and have a
          negative result (i.e. No second line appearing on the test, however
          faint it may be) or wear a mask throughout the wedding to protect our
          elderly family members.
        </Typography>
      </Grid>
    </Grid>
  );
}
