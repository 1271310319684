import {
  Button,
  CssBaseline,
  FormGroup,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useViewerContext } from "../viewer-context/component";
import { LoadingSpinnner } from "../common/loading-spinner";

interface GuestInfo {
  email: string;
  firstName: string;
  lastName: string;
  hasPlusOne: boolean;
  plusOneFirstName: string;
  plusOneLastName: string;
  isCouple: boolean;
  coupleFirstName: string;
  coupleLastName: string;
}

export function GuestInfoRSVP(): JSX.Element {
  const [guestInfo, setGuestInfo] = useState<GuestInfo>();
  const viewerContext = useViewerContext();

  const [isAttending, setIsAttending] = useState<boolean>(true);
  const [plusOneIsAttending, setPlusOneIsAttending] = useState<boolean>(true);

  useEffect(() => {
    if (guestInfo || !viewerContext) {
      return;
    }

    async function getGuestInfo() {
      const guestInfoResponse = await fetch(
        `https://api.winnieandcharlie.com/rsvp-info?email=${viewerContext?.email}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (guestInfoResponse.status === 200) {
        const guestInfo = await guestInfoResponse.json();
        setGuestInfo(guestInfo);
      } else if (guestInfoResponse.status === 401) {
        viewerContext?.invalidateLogin();
      } else if (guestInfoResponse.status === 403) {
        viewerContext?.forbidLogin();
      }
    }

    getGuestInfo();
  }, [guestInfo, viewerContext]);

  const updatePlusOneInfo = useCallback(
    async (
      firstName: string | null,
      lastName: string | null,
      hasPlusOne = true
    ) => {
      const updatePlusOneInfoResponse = await fetch(
        "https://api.winnieandcharlie.com/rsvp-info",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: guestInfo?.email,
            plusOneFirstName: firstName || "",
            plusOneLastName: lastName || "",
            hasPlusOne,
          }),
          credentials: "include",
        }
      );

      if (updatePlusOneInfoResponse.status === 200) {
        const newGuestInfo = await updatePlusOneInfoResponse.json();
        setGuestInfo(newGuestInfo);
      } else if (updatePlusOneInfoResponse.status === 401) {
        viewerContext?.invalidateLogin();
      } else if (updatePlusOneInfoResponse.status === 403) {
        viewerContext?.forbidLogin();
      }
    },
    [guestInfo]
  );

  if (!guestInfo) {
    return (
      <Grid container spacing={4} justifyContent="center">
        <LoadingSpinnner />
      </Grid>
    );
  }

  const hasPlusOne = guestInfo?.hasPlusOne;
  const plusOneFirstName = guestInfo?.plusOneFirstName;
  const plusOneLastName = guestInfo?.plusOneLastName;
  const isCouple = guestInfo?.isCouple;

  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid
        container
        item
        xs={12}
        md={6}
        justifyContent="center"
        alignItems="center"
      >
        <Typography color="textSecondary">
          {guestInfo?.firstName} {guestInfo?.lastName}
        </Typography>
      </Grid>
      <Grid container item xs={12} md={6}>
        <Grid container item xs={6} justifyContent="flex-start">
          <Button
            fullWidth
            variant="contained"
            color={isAttending ? "primary" : "secondary"}
            onClick={() => setIsAttending(true)}
          >
            I'm Attending
          </Button>
        </Grid>
        <Grid container item xs={6} justifyContent="flex-start">
          <Button
            fullWidth
            variant="contained"
            color={isAttending ? "secondary" : "primary"}
            style={{ marginLeft: "1rem" }}
            onClick={() => setIsAttending(false)}
          >
            I'm Unable to Attend
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {!isAttending && (
          <Typography color="textSecondary" paragraph>
            We're sorry you can't make it! Please inform the bride or groom
            directly.
          </Typography>
        )}
      </Grid>

      {hasPlusOne && (
        <PlusOneInfoForm
          firstName={plusOneFirstName}
          lastName={plusOneLastName}
          isAttending={plusOneIsAttending}
          setIsAttending={setPlusOneIsAttending}
          onSubmit={updatePlusOneInfo}
        />
      )}
      {hasPlusOne && (
        <PlusOneIntakeForm
          firstName={plusOneFirstName}
          lastName={plusOneLastName}
          onSubmit={updatePlusOneInfo}
        />
      )}
      {isCouple && (
        <CoupleInformation
          partnerFirstName={guestInfo?.coupleFirstName}
          partnerLastName={guestInfo?.coupleLastName}
        />
      )}
    </Grid>
  );
}

function PlusOneInfoForm(props: {
  firstName: string;
  lastName: string;
  isAttending: boolean;
  setIsAttending: (isAttending: boolean) => void;
  onSubmit: (
    firstName: string | null,
    lastName: string | null,
    hasPlusOne: boolean
  ) => Promise<void>;
}): JSX.Element {
  const { isAttending, setIsAttending } = props;
  const [hasConfirmedAttendance, setHasConfirmedAttendance] =
    useState<boolean>(false);

  const handleConfirmAttendance = useCallback(async () => {
    if (isAttending) {
      setHasConfirmedAttendance(true);
    } else {
      await props.onSubmit(null, null, false);
    }
  }, [isAttending, props]);

  if (hasConfirmedAttendance) {
    return (
      <Grid container item xs={12} justifyContent="center">
        <Typography color="textSecondary" paragraph>
          Thanks for confirming your attendance!
        </Typography>
      </Grid>
    );
  }

  if (!props.firstName && !props.lastName) {
    return <></>;
  }

  return (
    <>
      <Grid
        container
        item
        xs={12}
        md={6}
        justifyContent="center"
        alignItems="center"
      >
        <Typography color="textSecondary">
          {props.firstName} {props.lastName}
        </Typography>
      </Grid>
      <Grid container item xs={12} md={6} justifyContent="center">
        <Grid container item xs={6} md={6} justifyContent="flex-start">
          <Button
            fullWidth
            variant="contained"
            color={isAttending ? "primary" : "secondary"}
            onClick={() => setIsAttending(true)}
          >
            Guest is Attending
          </Button>
        </Grid>
        <Grid container item xs={6} md={6} justifyContent="flex-start">
          <Button
            fullWidth
            variant="contained"
            color={isAttending ? "secondary" : "primary"}
            style={{ marginLeft: "1rem" }}
            onClick={() => setIsAttending(false)}
          >
            Guest is not Attending
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        style={{ marginTop: "2rem" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmAttendance}
        >
          Submit
        </Button>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function PlusOneIntakeForm(props: {
  firstName: string;
  lastName: string;
  onSubmit: (firstName: string, lastName: string) => void;
}): JSX.Element {
  const classes = useStyles();
  const [guestFirstName, setGuestFirstName] = useState("");
  const [guestLastName, setGuestLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState<Error | undefined>();
  const [lastNameError, setLastNameError] = useState<Error | undefined>();

  const onGuestFirstNameChange = useCallback((event: React.SyntheticEvent) => {
    setGuestFirstName((event.target as HTMLInputElement).value);
  }, []);

  const onGuestLastNameChange = useCallback((event: React.SyntheticEvent) => {
    setGuestLastName((event.target as HTMLInputElement).value);
  }, []);

  const onSubmit = useCallback(() => {
    setFirstNameError(undefined);
    setLastNameError(undefined);

    let hasError = false;

    // If guestFirstName is empty, set error
    if (!guestFirstName) {
      setFirstNameError(new Error("First name is required"));
      hasError = true;
    }

    // If guestLastName is empty, set error
    if (!guestLastName) {
      setLastNameError(new Error("Last name is required"));
      hasError = true;
    }

    if (hasError) return;

    // If guestFirstName does not meet regex for letters, hyphens, or spaces only, set error
    if (!/^[a-zA-Z- ]*$/.test(guestFirstName)) {
      setFirstNameError(
        new Error("First name must only contain letters, hyphens, or spaces")
      );
      hasError = true;
    }

    // If guestLastName does not meet regex for letters, hyphens, or spaces only, set error
    if (!/^[a-zA-Z- ]*$/.test(guestLastName)) {
      setLastNameError(
        new Error("Last name must only contain letters, hyphens, or spaces")
      );
      hasError = true;
    }

    if (hasError) return;

    props.onSubmit(guestFirstName, guestLastName);
  }, [props, guestFirstName, guestLastName]);

  if (props.firstName || props.lastName) {
    return <></>;
  }

  return (
    <Grid container item xs={12} justifyContent="center">
      <CssBaseline />
      <Grid item xs={12} justifyContent="center">
        <Typography color="textSecondary" paragraph>
          Looks like you've mentioned you're bringing a guest! Please fill out
          their info below:
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <FormGroup className={classes.paper}>
          <form className={classes.form}>
            <TextField
              fullWidth
              required
              autoFocus
              label="Guest First Name"
              id="guest-first-name-input"
              inputProps={{ style: { color: "#1F1F1F" } }}
              value={guestFirstName}
              onChange={onGuestFirstNameChange}
              variant="outlined"
              error={!!firstNameError}
              style={{ marginBottom: "1rem" }}
            />
            <TextField
              fullWidth
              required
              label="Guest Last Name"
              variant="outlined"
              value={guestLastName}
              onChange={onGuestLastNameChange}
              inputProps={{ style: { color: "#1F1F1F" } }}
              id="guest-last-name-input"
              error={!!lastNameError}
              aria-errormessage={lastNameError?.message}
              style={{ marginBottom: "1rem" }}
            />
          </form>
        </FormGroup>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

function CoupleInformation(props: {
  partnerFirstName: string;
  partnerLastName: string;
}): JSX.Element {
  const [partnerIsAttending, setPartnerIsAttending] = useState<boolean>(true);

  if (!props.partnerFirstName && !props.partnerLastName) {
    return <></>;
  }

  return (
    <>
      <Grid
        container
        item
        xs={12}
        md={6}
        justifyContent="center"
        alignItems="center"
      >
        <Typography color="textSecondary">
          {props.partnerFirstName} {props.partnerLastName}
        </Typography>
      </Grid>
      <Grid container item xs={12} md={6} justifyContent="center">
        <Grid container item xs={6} md={6} justifyContent="flex-start">
          <Button
            fullWidth
            variant="contained"
            color={partnerIsAttending ? "primary" : "secondary"}
            onClick={() => setPartnerIsAttending(true)}
          >
            I'm Attending
          </Button>
        </Grid>
        <Grid container item xs={6} md={6} justifyContent="flex-start">
          <Button
            fullWidth
            variant="contained"
            color={partnerIsAttending ? "secondary" : "primary"}
            style={{ marginLeft: "1rem" }}
            onClick={() => setPartnerIsAttending(false)}
          >
            I'm Unable to Attend
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {!partnerIsAttending && (
          <Typography color="textSecondary" paragraph>
            We're sorry you can't make it! Please inform the bride or groom
            directly.
          </Typography>
        )}
      </Grid>
    </>
  );
}
