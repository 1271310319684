// Create React context for the viewer
//
// This context is used to store the viewer's information, such as their
// email address, first name, and last name. This information is used to
// identify the viewer when they submit their RSVP.
//
// The context is created using the React Context API. The context is
// initialized with a default value of null. The default value is used
// when the viewer is not logged in.

import React, { useCallback } from "react";

export interface Viewer {
  isLoggedIn: boolean;
  setLoggedIn: (isLoggedIn: boolean) => void;
  loginWasInvalidated: boolean;
  loginWasForbidden: boolean;
  invalidateLogin: () => void;
  forbidLogin: () => void;
  email: string;
  setEmail: (email: string) => void;
  resetLoginState: () => void;
}

const ViewerContext = React.createContext<Viewer | null>(null);
const ViewerProvider = ViewerContext.Provider;
export const ViewerConsumer = ViewerContext.Consumer;
export const useViewerContext = () => React.useContext(ViewerContext);

export function ViewerContextManager(props: { children: React.ReactNode }) {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [loginWasInvalidated, setLoginWasInvalidated] = React.useState(false);
  const [loginWasForbidden, setLoginWasForbidden] = React.useState(false);

  const setLoggedIn = useCallback((isLoggedIn: boolean) => {
    setIsLoggedIn(isLoggedIn);
  }, []);

  const invalidateLogin = useCallback(() => {
    setLoginWasInvalidated(true);
    setIsLoggedIn(false);
  }, []);

  const forbidLogin = useCallback(() => {
    setLoginWasForbidden(true);
    setIsLoggedIn(false);
  }, []);

  const resetLoginState = useCallback(() => {
    setLoginWasForbidden(false);
    setIsLoggedIn(false);
    setLoginWasInvalidated(false);
  }, []);

  return (
    <ViewerProvider
      value={{
        isLoggedIn,
        setLoggedIn,
        loginWasInvalidated,
        loginWasForbidden,
        forbidLogin,
        invalidateLogin,
        email,
        setEmail,
        resetLoginState,
      }}
    >
      {props.children}
    </ViewerProvider>
  );
}
