import { Loop } from "@material-ui/icons";
import "./loading-spinner.css";

export function LoadingSpinnner(): JSX.Element {
  return (
    <div className="loading-spinner">
      <Loop className="loading-spinner__icon" />
    </div>
  );
}
