import { Grid, Typography } from "@material-ui/core"
import { Favorite, LocalDining, Today } from "@material-ui/icons"
import peanutBigEyes from "../../assets/peanut-big-eyes.jpg";
import "./styles.css";

export function MainTab(): JSX.Element {
  return <Grid container spacing={4} justifyContent="center">
    <Grid item xs={12} md={4} alignContent="center">
      <div style={{ fontSize: "4.5rem" }}>
        <Today color="action" fontSize="inherit" />
      </div>
      <Typography color="textSecondary">
        July 8, 2023
      </Typography>
      <Typography color="textSecondary">
        5:00 PM - 10:00 PM
      </Typography>
    </Grid>
    <Grid item xs={12} md={4} alignContent="center">
      <div style={{ fontSize: "4.5rem" }}>
        <Favorite color="action" fontSize="inherit" />
      </div>
      <Typography color="textSecondary">
        Ceremony begins at 5:00 PM
      </Typography>
      <Typography color="textSecondary">
        Guest arrival begins 30 minutes prior
      </Typography>
    </Grid>
    <Grid item xs={12} md={4} alignContent="center">
      <div style={{ fontSize: "4.5rem" }}>
        <LocalDining color="action" fontSize="inherit" />
      </div>
      <Typography color="textSecondary">
        Reception begins at 6:00 PM
      </Typography>
    </Grid>
    <Grid item xs={12} alignContent="center">
      <Typography color="textSecondary" paragraph>
        Attire: Dressy Casual
      </Typography>
    </Grid>
    <Grid item xs={12} alignContent="center">
      <Typography color="textSecondary" paragraph>
        <strong>Notice: </strong>A dog will be present at the ceremony and reception.
      </Typography>
      <img src={peanutBigEyes} alt="Peanut the dog" className="pet-image" />
      <Typography color="textSecondary" paragraph>
        Warning, she is known to like begging for food and licking people.
      </Typography>
      <Typography color="textSecondary" paragraph>
        (Please don't feed her anything though)
      </Typography>
    </Grid>
  </Grid>
}
